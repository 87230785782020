
/**
 * @name: specs
 * @author: lili
 * @date: 2023-06-01 11:21
 * @description：销售管理-视频管理-添加，修改  规格
 * @update: 2023-06-01 11:21
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import config from "@/config";
import {Message} from "element-ui";
import {courseVideoSkuVoList, ICourse} from "@/apis/coursesManage/courses/types";

@Component({})
export default class ProductSpecs extends Vue {
  config = config;
  // 新增修改表单
  @Prop(Object) modelForm!: ICourse;
  // 新增修改表单rules
  @Prop(Object) rules!: any;

  //列表数据
  tableData: any[] = []
  loading = false;

  specsData:courseVideoSkuVoList= {
    skuName: '',
    sort:0,
    video: '',
    index: 0
  };

  // 编辑弹窗
  dialogVisible: boolean = false;

  @Watch('modelForm')
  onChangeValue(newVal: any, oldVal: any){
    console.log(newVal,'监听数据')
    if(newVal.courseVideoSkuVoList){
      this.tableData=newVal.courseVideoSkuVoList;
    }
  }

  /**
   * 添加规格
   *
   */
  handleAddSpecsName() {
    if (!this.specsData.skuName || this.specsData.skuName == '') {
      this.$message.error("请输入分P名称")
      return
    }
    if (!this.specsData.sort ) {
      this.$message.error("请输入排序")
      return
    }
    let index = this.tableData.findIndex(e => e.sort == this.specsData.sort);
    if (index != -1) {
      this.$message.error("排序已存在！")
      return
    }
    this.loading = true;
    let obj = {
      skuName: this.specsData.skuName,
      sort: this.specsData.sort,
      video: '',
      index: this.tableData.length
    }
    this.tableData.push(obj);
    this.tableData = this.tableData.sort((a, b) => a.sort - b.sort);
    this.modelForm.courseVideoSkuDtoList = this.tableData;
    this.loading = false
  }


  /**
   * 修改
   * @param index
   */
  handleEdit(index: number) {
    this.dialogVisible = true;
    this.specsData = this.tableData[index];
  }

  /**
   * 删除
   * @param index
   */
  handleDel(index: number) {
    this.tableData.splice(index, 1);
    this.modelForm.courseVideoSkuDtoList = this.tableData;
  }

  /**
   * 修改规格
   */
  enterDialog() {
    if (!this.specsData.skuName || this.specsData.skuName == '') {
      this.$message.error("请输入分P名称")
      return
    }
    if (!this.specsData.sort ) {
      this.$message.error("请输入排序")
      return
    }
    let index = this.tableData.findIndex(e => e.sort == this.specsData.sort);
    if (index != -1) {
      this.$message.error("排序已存在！")
      return
    }
    this.tableData[this.specsData.index||0] = this.specsData;
    this.tableData = this.tableData.sort((a, b) => a.sort - b.sort);
    this.modelForm.courseVideoSkuDtoList = this.tableData;
    this.dialogVisible = false;
    this.specsData = {
      skuName: '',
      sort: 0,
      video: '',
      index: 0
    }
  }

  /**
   * 上传视频前检验
   * @param file
   */
  beforeVideoUpload(file: File) {
    let fileSize = file.size / 1024 / 1024 < 50;   //控制大小  修改50的值即可
    if (
      [
        "video/mp4",
        "video/ogg",
        "video/flv",
        "video/avi",
        "video/wmv",
        "video/rmvb",
        "video/mov",
      ].indexOf(file.type) == -1     //控制格式
    ) {
      Message.error("请上传正确的视频格式");
      return false;
    }
    if (!fileSize) {
      Message.error("视频大小不能超过50MB");
      return false;
    }
  }
  /**
   * 下一步，上一步
   * @param number
   *
   */
  handleNext(tab: string) {
    this.$emit('Next', this.modelForm,tab)
  }

  created() {
  }
}
